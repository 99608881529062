.covered {
	display: block;
	position: relative;
	z-index: 1;

	& > .background {
		@include stretch();
		z-index: -2;
		background-position: center center;
		background-size: cover;
	}

	.overlay {
		@include stretch();
		z-index: -1;
		background-color: rgba(#FFFFFF, .4);
	}

	&.inverse {
		color: #FFF;

		.overlay {
			background-color: rgba(#000000, .2);
		}
	}

	.icon-cover {
		[class^="launch-icon-"], [class*=" launch-icon-"] {
			display: block;
			font-size: 64px;

			& + h1,
			& + h2,
			& + .h1,
			& + .h2 {
				margin-top: 0;
			}
		}
	}

	&.gradient-bg {
		@include gradient-directional(darken(rgba($brand-primary, .8), 0%), darken(rgba(#11345C, .8), 0%));
		color: #FFF;
	}
}