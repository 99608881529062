.ticket-banner {
	position: relative;

	.ticket-text {
		position: absolute;
		left: 25px;
		top: 30px;
		bottom: 30px;
		width: 60%;
		font-size: 18px;
		line-height: 20px;
		font-weight: bold;
		text-transform: uppercase;

		h1, .h1, p {
			margin-bottom: 0;
		}

		@media (min-width: $screen-sm-min) {
			font-size: 32px;
			line-height: 40px;
			top: 40px;
			bottom: 40px;
			left: 50px;
		}

		@media (min-width: $screen-md-min) {
			font-size: 40px;
			line-height: 50px;
			top: 55px;
			bottom: 55px;
			left: 50px;
		}

		@media (min-width: $screen-lg-min) {
			font-size: 64px;
			line-height: 80px;
			left: 40px;
			top: 70px;
			bottom: 70px;
		}

		hr {
			margin-top: 8px;
			margin-bottom: 8px;
			border-top-width: 3px;
		}
	}
}

.bas {
	padding: 1em;
	box-shadow: 0 1px 4px rgba(0,0,0,0.4);

	.bas-inner {
		border: 4px solid $brand-primary;
		padding: 1em;
	}
}

.purchasable {
	text-align: center;
	padding: 2em;
	border: 3px solid $brand-primary;
	border-radius: 4px;

	&-solo {
		background: #FFF;
		color: $brand-primary;
	}

	&-group {
		background: $brand-primary;
		color: #FFF;

		.btn-link {
			color: #FFF;
		}
	}
}