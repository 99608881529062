.ContactForm {
	margin-bottom: 50px;

	&__Upper {
		@include gradient-directional(darken(rgba($brand-primary, .8), 0%), darken(rgba(#11345C, .8), 0%));
		padding: 2em 0;

		.control-label {
			color: #FFF;
		}
	}

	&__Lower {
		.control-label {
			padding: 2em 0 1em;
		}

		textarea.form-control {
			border-radius: 0;
			border-color: transparent;
			background: #F2F2F2;
			box-shadow: none;

			&:active, &:focus {
				border-color: #DDDDDD;
				background: #FFF;
			}
		}
	}

	&__Footer {
		text-align: right;
	}
}

.ContactFormInfo {
	font-size: 20px;
	margin-bottom: 3em;

	[class^="launch-icon-"]:before, [class*=" launch-icon-"]:before {
		font-size: 40px;

		@media (min-width: $screen-sm-min) {
			font-size: 64px;
		}
	}

	p {
		margin-bottom: 0;
	}
}