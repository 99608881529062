@font-face {
  font-family: 'Launch Icons';
  src: url('../fonts/fontello.eot?8762258');
  src: url('../fonts/fontello.eot?8762258#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?8762258') format('woff2'),
       url('../fonts/fontello.woff?8762258') format('woff'),
       url('../fonts/fontello.ttf?8762258') format('truetype'),
       url('../fonts/fontello.svg?8762258#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
 
[class^="launch-icon-"]:before, [class*=" launch-icon-"]:before {
  font-family: "Launch Icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.launch-icon-menu:before { content: '\e800'; } /* '' */
.launch-icon-map-marker-2:before { content: '\e801'; } /* '' */
.launch-icon-email:before { content: '\e802'; } /* '' */
.launch-icon-email-sent:before { content: '\e803'; } /* '' */
.launch-icon-award-hand:before { content: '\e804'; } /* '' */
.launch-icon-high-school:before { content: '\e805'; } /* '' */
.launch-icon-conference:before { content: '\e806'; } /* '' */
.launch-icon-kinect:before { content: '\e807'; } /* '' */
.launch-icon-launch:before { content: '\e808'; } /* '' */
.launch-icon-map-marker:before { content: '\e809'; } /* '' */
.launch-icon-phone:before { content: '\e80a'; } /* '' */
.launch-icon-money-hand:before { content: '\e80b'; } /* '' */
.launch-icon-meeting:before { content: '\e80c'; } /* '' */
.launch-icon-chemistry:before { content: '\e80d'; } /* '' */
.launch-icon-atom:before { content: '\e80e'; } /* '' */
.launch-icon-facebook:before { content: '\e80f'; } /* '' */
.launch-icon-instagram:before { content: '\e810'; } /* '' */
.launch-icon-linkedin:before { content: '\e811'; } /* '' */
.launch-icon-twitter:before { content: '\e812'; } /* '' */
.launch-icon-vimeo:before { content: '\e813'; } /* '' */
.launch-icon-desk-set:before { content: '\e814'; } /* '' */
.launch-icon-graduation:before { content: '\e815'; } /* '' */
.launch-icon-business:before { content: '\e816'; } /* '' */
.launch-icon-address-book:before { content: '\e817'; } /* '' */
.launch-icon-play:before { content: '\e818'; } /* '' */
.launch-icon-trash:before { content: '\e819'; } /* '' */
.launch-icon-pencil:before { content: '\e81a'; } /* '' */
.launch-icon-plus:before { content: '\e81b'; } /* '' */
