$blog-gutter: 40px;

.BlogRoll {
	@include make-row($blog-gutter);

	&__Main,
	&__Post {
		@include make-xs-column(12, $blog-gutter);
		@include make-md-column(8, $blog-gutter);
	}

	&__Sidebar {
		@include make-xs-column(12, $blog-gutter);
		@include make-md-column(4, $blog-gutter);

		&__Search {
			.input-group-btn .btn {
				background: #FFF;
				border: 1px solid $input-border;
				border-left: 0;
				box-shadow: none;
			}
		}

		&__Tags {
			text-transform: uppercase;
			margin-top: 3em;
			margin-bottom: 6em;
		}

		&__Recent {
			margin-bottom: 6em;
			.media-object {
				margin-right: 10px;
			}
		}

		&__Popular {
			.label {
				font-size: 14px;
				text-transform: uppercase;
				border-radius: 50vh;
				margin-bottom: 10px;
				display: inline-block;
				padding: $padding-large-vertical $padding-large-horizontal;
			}
		}
	}

	&__Post {

		&Details {
			margin-bottom: 2em;
		}

		&Content {

		}
	}
}

.BlogPreview {
	margin-bottom: 2em;

	&__Image {
		img {
			width: 100%;
			height: auto;
		}
	}

	&__Info {
		@extend .media;
		margin-bottom: 1em;
	}

	&__Date {
		float: left;
		width: 60px;
		height: 60px;
		border: 5px solid $brand-primary;
		text-align: center;
		padding-top: 10px;
		margin-right: 10px;
	}

	&__DateDay {
		font-size: 20px;
		line-height: 20px;
		display: block;
		margin: 0;
	}

	&__DateMonth {
		font-size: 12px;
		line-height: 12px;
		display: block;
		margin: 0;
	}

	&__Details {
		@extend .media-body;
	}

	&__Title {
		margin: 6px 0 4px;
	}

	&__Content {
		font-size: 18px;
	}

	&__ReadMore {
		text-transform: uppercase;
	}
}