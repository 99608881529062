.EntrepreneurBanner {
	@extend .Banner;
	@extend .inverse;

	&__Title {
		line-height: 50px;
		@media (min-width: $screen-md-min) {
			width: 300px;
		}
	}

	&__Quote {
		border-left: 0;
		text-align: center;
		padding: 0;

		@media (min-width: $screen-md-min) {
			max-width: 80%;
			margin: 100px auto 40px;
		}

		footer {
			color: #DDD;
		}
	}
}