@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-medium-webfont.eot');
    src: url('montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-medium-webfont.woff2') format('woff2'),
         url('montserrat-medium-webfont.woff') format('woff'),
         url('montserrat-medium-webfont.ttf') format('truetype'),
         url('montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-mediumitalic-webfont.eot');
    src: url('montserrat-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-mediumitalic-webfont.woff2') format('woff2'),
         url('montserrat-mediumitalic-webfont.woff') format('woff'),
         url('montserrat-mediumitalic-webfont.ttf') format('truetype'),
         url('montserrat-mediumitalic-webfont.svg#montserratmedium_italic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-bold-webfont.eot');
    src: url('montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff'),
         url('montserrat-bold-webfont.ttf') format('truetype'),
         url('montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-bolditalic-webfont.eot');
    src: url('montserrat-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-bolditalic-webfont.woff2') format('woff2'),
         url('montserrat-bolditalic-webfont.woff') format('woff'),
         url('montserrat-bolditalic-webfont.ttf') format('truetype'),
         url('montserrat-bolditalic-webfont.svg#montserratbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;

}