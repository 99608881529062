#topnav {
	background-color: transparent;
	// padding-top: 15px;
	// padding-bottom: 15px;

	@include gradient-directional(darken(rgba($brand-primary, .8), 0%), darken(rgba(#11345C, .8), 0%));

	.navbar-brand {
		img {
			max-height: 100%;

			@media (max-width: $screen-xs-max) {
				max-height: 28px;
			}
		}
	}

	.navbar-toggle {
		margin-left: 15px;
		color: #FFF;
		// height: 35px;
		margin-bottom: 0;

		&:hover, &:focus {
			// color: 
		}
	}
}

.navmenu {
	z-index: 1;
	padding-bottom: 50px;

	.navmenu-brand {
		position: absolute;
		text-align: center;
		bottom: 0;
		left: 0;
		right: 0;
		display: none;

		@media (max-width: $screen-sm-min) and (max-height: 450px) {
			display: none;
		}

		@media (min-width: $screen-sm-min) and (max-height: 700px) {
			display: none;
		}
	}

	li {
		&.active >  a {
			color: $brand-primary !important;
			background-color: #FFF !important;
		}

		a {
			border-bottom: 1px solid #E0E0E0;
			text-transform: uppercase;

			@media (min-width: $screen-md-min) {
				padding: $padding-large-horizontal - 4px;
			}
		}
	}

	.dropdown-menu {
		a {
			@media (min-width: $screen-md-min) {
				padding: $padding-base-horizontal $padding-large-horizontal;
			}
		}
	}
}

.canvas {
	position: relative;
	left: 0;
	z-index: 2;
	min-height: 100%;
	padding: ($navbar-height + 0) 0 0 0;
	background: #fff;

	& > .alert {
		border-radius: 0;
		border-width: 0;
		border-bottom-width: 1px;
		margin-bottom: 0;
	}
}

.logged-in {
	#topnav {
		top: 50px;
	}
}

#admin_nav {
	min-height: 50px;
	height: 50px;
	z-index: 1050;

	.navbar-text {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.navbar-toggle {
		margin-top: 8px;
		margin-bottom: 8px;
	}

	.navbar-nav > li > a {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.navmenu-nav.dropdown-menu {
		@media (min-width: $screen-sm-min) {
			position: absolute;
		}
	}

	@media (max-width: $screen-md-max) {
		.navbar-collapse {
			background-color: #333;
		}
	}
}

@media (min-width: 0) {
	#topnav .navbar-toggle {
		// display: block; /* force showing the toggle */
	}
}

@media (min-width: 992px) {
	body {
		padding: 0;
	}
	.navbar {
		right: 0;
	}
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
	border-color: $brand-primary;
}

.navmenu-nav .dropdown-backdrop {
	pointer-events: none;
}

.navmenu-fixed-right .dropdown-menu > li > a {
	white-space: normal;
}

#topnav-collapse {
	.navbar-nav {
		margin-top: 10px;
		margin-right: 45px;

		& > li > a {
			color: #FFF;
		}
	}

	.navbar-nav > .open > a, .navbar-nav > .open > a:hover, .navbar-nav > .open > a:focus,
	.navbar-nav > .active > a, .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus {
		background: rgba(#000, 0.1);
	}

	.navbar-nav > li > .dropdown-menu {
		border-radius: 4px;
		margin-top: 5px;
	}

	.dropdown-menu > li {
		border-bottom: 1px solid #DDD;

		&:last-child {
			border-bottom-width: 0px;
		}

		a {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
	@media (min-width: $screen-sm-min) {
		max-height: 100%;
	}
}