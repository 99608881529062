.AboutBanner {
	@extend .Banner;
}

.AboutTabs {
	background-color: $brand-primary;
	padding: 3em 0 2em;

	.nav-tabs {
		border-bottom: 2px solid #FFF;
		display: table;
		width: 100%;
	}

	.nav-tabs > li {
		display: table-cell;
		width: 50%;
		vertical-align: bottom;

		@media (min-width: $screen-sm-min) {
			width: 25%;
		}
	}

	.nav-tabs > li a {
		border: 0;
		text-transform: uppercase;
		text-align: center;
		margin-right: 0;
		margin-bottom: 4px;

		[class^="launch-icon-"], [class*=" launch-icon-"] {
			display: block;
		}

		&:hover, &:focus {
			border-bottom: 6px solid #FFF;
			margin-bottom: -2px;
		}

		@media (min-width: $screen-sm-min) {
			font-size: 16px;

			[class^="launch-icon-"], [class*=" launch-icon-"] {
				font-size: 24px;
			}
		}

		@media (min-width: $screen-md-min) {
			font-size: 18px;

			[class^="launch-icon-"], [class*=" launch-icon-"] {
				font-size: 32px;
			}
		}

		@media (min-width: $screen-lg-min) {
			font-size: 24px;

			[class^="launch-icon-"], [class*=" launch-icon-"] {
				font-size: 64px;
			}
		}
	}

	.nav-tabs > li > a,
	.nav-tabs > li.active > a {
		color: #FFF;
		background: transparent;
	}

	.nav-tabs > li.active > a {
		border-bottom: 6px solid #5F6063;
		margin-bottom: -2px;
	}
}

.featured-background-image {
	position: relative;
	padding-top: 2em;
	padding-bottom: 2em;
	margin-bottom: 3em;
	z-index: 1;

	.image-frame {
		display: none;
		@extend .covered;
		z-index: -1;

		@media (min-width: $screen-md-min) {
			display: block;
			position: absolute;
			left: 50%;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}

	.content-frame {
		@media (min-width: $screen-md-min) {
			background: rgba(#FFF, .7);
			padding: 2em;
			border-radius: 8px 0 0 8px;
			border: 1px solid #EBEBEB;
			width: 60%;
		}
	}
}

.social-icon-list {
	@extend .list-inline;

	li {
		margin: 0;
	}

	a {
		padding: 5px 0;
		color: $brand-primary;
		text-decoration: none;
	}
}

.logo-set {
	@extend .list-inline;
	font-size: 0;
	text-align: center;

	li {
		display: block;
		font-size: $font-size-base;
		padding: 0 3em;
		margin-bottom: 3em;

		img {
			max-width: 100%;
			height: 100px;
			// max-height: 100px;
		}

		@media (min-width: $screen-sm-min) {
			display: inline-block;
		}
	}

	a {
		display: block;
	}
}

.state-examples {
	text-align: center;

	.covered {
		padding: 6em 0 3em;
	}

	.centered-state {
		padding: 0 3em;

		img {
			max-width: 300px;
			height: auto;
			max-height: 100px;
			@media (min-width: $screen-md-min) {
				max-height: 150px;
			}
		}
	}

	h2 {
		color: #FFF;
		font-size: 36px;
		text-transform: uppercase;
	}

	.state-examples-content {
		padding-top: 2em;
		padding-bottom: 5em;

		@media (min-width: $screen-md-min) {
			font-size: 20px;

		}
	}
}
