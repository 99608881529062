.EntrepreneurCards {
	@include make-row(5px);
}

.EntrepreneurCard {
	@include make-xs-column(12, 5px);
	@include make-sm-column(6, 5px);
	@include make-md-column(4, 5px);

	margin-bottom: 5px;

	&__Inner {
		@extend .covered;
		@extend .inverse;

		min-height: 200px;
		text-align: center;
		padding: 40px;

		@media (min-width: $screen-sm-min) {
			min-height: 400px;

			.EntrepreneurCard__Title {
				margin-top: 40px;
			}
		}

		@media (min-width: $screen-md-min) {
			
		}

		@media (min-width: $screen-lg-min) {
			
		}
	}

	&__Title {
		margin-bottom: 40px;
		font-size: 26px;
	}

	&__Button {

	}
}