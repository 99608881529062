.InitiativeStamp {
	text-decoration: none !important;

	&__Logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 15px;
		margin-bottom: 15px;

		@media (min-width: $screen-lg-min) {
			height: 170px;
		}

		img {
			align-self: center; 
		}
	}

	.btn {
		white-space: normal;
		color: #FFF;
	}
}

.InitiativeStamps {
	margin-bottom: 50px;

	& > div {
		&:nth-child(1) {
			.InitiativeStamp .btn {
				background: #32A8CB;
			}
		}

		&:nth-child(2) {
			.InitiativeStamp .btn {
				background: #90BB52;
			}
		}

		&:nth-child(3) {
			.InitiativeStamp .btn {
				background: #F08140;
			}
		}

		&:nth-child(4) {
			.InitiativeStamp .btn {
				background: #036F8C;
			}
		}
	}
}

.InitiativeBrochures {

}

.InitiativeBrochure {
	// position: relative;
	display: flex;
	flex-direction: column;
	color: #FFF;
	margin-bottom: $grid-gutter-width;

	@media (min-width: $screen-md-min) {
		flex-direction: row;
		margin-bottom: 0;
	}

	&__Image {
		min-height: 240px;

		@media (min-width: $screen-md-min) {
			width: 60%;
		}
	}

	&__Content {
		@media (min-width: $screen-md-min) {
			width: 40%;
			font-size: 20px;
		}

		.btn {
			padding-left: 40px;
			padding-right: 40px;
			border: 1px solid #FFF;
			border-radius: 50vh;
			box-shadow: none;
			color: inherit;
			text-transform: none;
			margin-top: $grid-gutter-width;
			margin-bottom: $grid-gutter-width;
		}
	}

	&__ContentInner {
		padding: ($grid-gutter-width / 2);
		display: inline-block;
		text-align: left;

		@media (min-width: $screen-md-min) {
			padding: 50px 0;
			width: (($screen-md - ($grid-gutter-width)) * 0.4) - $grid-gutter-width;
		}

		@media (min-width: $screen-lg-min) {
			width: (($screen-lg - ($grid-gutter-width)) * 0.4) - $grid-gutter-width;
		}
	}

	&__Title {
		@media (min-width: $screen-md-min) {
			font-size: 45px;
			line-height: 52px;
			margin-bottom: 30px;
		}

		@media (min-width: $screen-lg-min) {
			font-size: 52px;
			line-height: 58px;
		}
	}

	&:nth-child(1) {
		background: #32A8CB;

		.InitiativeBrochure__Image {
			@media (min-width: $screen-md-min) {
				margin-right: $grid-gutter-width;
			}
		}
	}

	&:nth-child(2) {
		background: #90BB52;

		.InitiativeBrochure__Content {
			text-align: right;
		}

		.InitiativeBrochure__Image {
			@media (min-width: $screen-md-min) {
				margin-left: $grid-gutter-width;
			}
		}

		@media (max-width: $screen-sm-max) {
			flex-direction: column-reverse;
		}
	}

	&:nth-child(3) {
		background: #F08140;

		.InitiativeBrochure__Image {
			@media (min-width: $screen-md-min) {
				margin-right: $grid-gutter-width;
			}
		}
	}

	&:nth-child(4) {
		background: #036F8C;

		.InitiativeBrochure__Content {
			text-align: right;
		}

		.InitiativeBrochure__Image {
			@media (min-width: $screen-md-min) {
				margin-left: $grid-gutter-width;
			}
		}

		@media (max-width: $screen-sm-max) {
			flex-direction: column-reverse;
		}
	}

}

.InitiativeTimeline {
	margin-top: 50px;
	& > ul {
		list-style: none;
		padding: 0;
		display: flex;
		flex-direction: column;

		@media (min-width: $screen-md-min) {
			flex-direction: row;
		}

		& > li {
			padding: ($grid-gutter-width / 2);
			border-left: 3px solid #979797;

			@media (min-width: $screen-md-min) {
				flex: 1;
				margin-bottom: $grid-gutter-width;
			}

			& > img {
				height: auto;
				width: 100px;
				vertical-align: middle;
				margin-bottom: ($grid-gutter-width / 2);
				margin-right: ($grid-gutter-width / 2);
			}
		}
	}

	&__Date {
		font-weight: bold;
		font-size: 48px;
		vertical-align: middle;
		margin-right: ($grid-gutter-width / 2);

		@media (min-width: $screen-md-min) {
		}
	}

	&__Content {

	}
}

.InitiativeCards {
	margin-top: 50px;
}

.InitiativeCard {
	margin-bottom: ($grid-gutter-width / 2);
	padding: ($grid-gutter-width / 2);
	text-align: center;
	box-shadow: 0 0 11px rgba(#000, .1);

	@media (min-width: $screen-md-min) {
		padding: $grid-gutter-width;
	}

	img {
		height: auto;
		width: 95px;
		// margin-bottom: ($grid-gutter-width / 2);
	}

	.btn {
		margin-top: $grid-gutter-width;
	}
}