.Banner {
	@extend .covered;
	min-height: 200px;

	@media (min-width: $screen-sm-min) {
		min-height: 300px;
	}

	@media (min-width: $screen-md-min) {
		min-height: 350px;
	}

	@media (min-width: $screen-lg-min) {
		min-height: 400px;
	}

	h1 {
		text-align: center;
		color: #FFF;
		padding-top: 85px;
		text-shadow: 0 2px rgba(0,0,0,0.3);
		margin-top: 0;
		font-size: 60px;
		letter-spacing: 0.15em;

		@media (min-width: $screen-sm-min) {
			padding-top: 130px;
		}

		@media (min-width: $screen-md-min) {
			padding-top: 160px;
		}

		@media (min-width: $screen-lg-min) {
			padding-top: 190px;
		}
	}
}