.page-kic {
	.KitchenCover {
		.h1 span {
			display: inline-block;
			text-transform: uppercase;
			background: rgba(255,255,255, 0.7);
			padding: 5px 15px;
			font-size: 32px;
			font-weight: bold;

			@media (min-width: $screen-sm-min) {
				font-size: 48px;
			}

			@media (min-width: $screen-md-min) {
				font-size: 64px;
			}

			@media (min-width: $screen-lg-min) {
				font-size: 80px;
				padding: 5px 25px;
			}
		}
	}

	.kitchen-alt-1 {
		color: #58585B;
	}

	.kitchen-alt-2 {
		color: #378CAD;
	}

	.kitchen-alt-3 {
		color: #33A7D2;
	}

	.KitchenDescription {
		font-weight: normal;
	}

	.KitchenSubtitle {
		font-size: 48px;
		@media (min-width: $screen-sm-min) {
			font-size: 52px;
		}
		@media (min-width: $screen-md-min) {
			font-size: 78px;
		}
		@media (min-width: $screen-lg-min) {
			font-size: 120px;
		}
	}

	.KitchenWhisk {
		margin-top: 60px;
		@media (min-width: $screen-sm-min) {
			margin-top: 95px;
		}
		@media (min-width: $screen-md-min) {
			margin-top: 140px;
		}
		@media (min-width: $screen-lg-min) {
			margin-top: 180px;
		}
	}

	.StatisticsPanel {
		&__Stat {
			padding: 20px 40px;
			text-align: center;
			background-color: #378CAD;
			color: #FFF;
			font-size: 36px;
			text-transform: uppercase;
			margin-bottom: $grid-gutter-width / 2;

			p:last-child {
				margin-bottom: 0;
			}

			@media (min-width: $screen-sm-min) {
				padding: 20px;
				font-size: 50px;
			}
			@media (min-width: $screen-md-min) {
				padding: 30px 20px;
				font-size: 64px;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					z-index: -1;
					top: 0;
					bottom: 0;
					right: 0;
					left: -9999px;
					background: #378CAD;
				}
			}
			@media (min-width: $screen-lg-min) {
				padding: 40px 20px;
				font-size: 80px;
			}
		}
	}

	.KitchenProvisionsTitle {
		@media (min-width: $screen-md-min) {
			margin-top: 40px;
			font-size: 55px;
		}
		@media (min-width: $screen-lg-min) {
			margin-top: 90px;
			margin-bottom: 30px;
			font-size: 64px;
		}
	}

	.KitchenDonate {

		&__Costs {
			background-color: #58585B;
			color: #FFF;
			padding: 30px;
			font-size: 24px;
			position: relative;
			text-transform: uppercase;

			dl {
				margin-bottom: 0;

				dt {
					float: left;
				    width: 160px;
				    clear: left;
				    text-align: right;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    white-space: nowrap;
				    font-size: 32px;
				}

				dd {
					margin-left: 170px;
					padding-top: 6px;
				}
			}

			@media (min-width: $screen-md-min) {
				&:after {
					content: '';
					position: absolute;
					z-index: -1;
					top: 0;
					bottom: 0;
					left: 0;
					right: -9999px;
					background: #58585B;
				}
			}
		}
	}

		.btn-donate {
			@media (min-width: $screen-md-min) {
				font-size: 40px;
				font-weight: bold;
			}

			@media (min-width: $screen-lg-min) {
				font-size: 52px;
			}
		}
}

.page-kic {

	.canvas {
		background: #F6F6F6;
	}
}

h1.offset-print-stroke, 
.h1.offset-print-stroke {

	@media (min-width: $screen-lg-min) {
		font-size: 62px;
	}
}

.offset-print-stroke{
	color: #00708F;
	// position: relative;
	// display: inline-block;
	font-weight: bold;
	text-transform: uppercase;

	@media (min-width: $screen-lg-min) {

		// &:before {
		// 	display: inline-block;
		// 	content: attr(data-stroke-text);
		// 	position: absolute;
		// 	-webkit-text-stroke-width: 3px;
		// 	-webkit-text-stroke-color: #00708F;
		// 	margin-top: -3px;
		// 	margin-left: -3px;
		// 	color: transparent;
		// 	pointer-events: none;
		// }
	}

	// &.mce-edit-focus {
	// 	color: #00708F;

	// 	&:before {
	// 		display: none;
	// 	}
	// }
}

.PhotoPopout {
	position: relative;

	.background {
		display: none;

		@media (min-width: $screen-md-min) {
			display: block;
		}
	}

	&__Page {
		@media (min-width: $screen-md-min) {
			background: #FFF;
			padding: 25px;
			padding-top: 10px;
		}

		@media (min-width: $screen-md-min) {
			padding: 40px;
			padding-top: 20px;
		}
	}

	&__Button {
		@media (min-width: $screen-md-min) {
			margin-bottom: -100px;
		}
	}

	&--left {
		.background {
			@media (min-width: $screen-md-min) {
				right: 55%;
				bottom: 45px;
			}
		}

		.PhotoPopout__Page {
			margin-top: 45px;
		}
	}

	&--right {
		.background {
			@media (min-width: $screen-md-min) {
				left: 55%;
				top: 45px;
			}
		}

		.PhotoPopout__Page {
			margin-bottom: 45px;
		}
	}
}

.KitchenBusiness {
	text-align: center;
	text-transform: uppercase;
	display: block;
	margin-bottom: 25px;
	color: lighten($text-color, 10%);

	&:hover, &:focus {
		text-decoration: none;
		color: darken($text-color, 20%);

		img {
			opacity: 1;
		}
	}

	img {
		margin-bottom: 8px;
		opacity: 0.9;
	}
}

.KitchenIncubatorForm {
	background: #FFF;
	padding: 15px;

	@media (min-width: $screen-lg-min) {
		padding: 30px;
	}

	input[type=checkbox],
	input[type=radio] {
		margin-left: 0;
	}
}