html, body {
    height: 100%;
}

label {
    font-weight: normal;
}

#content {
    min-height: 400px;
}

.logged-in {
	.editable, 
	.BlogRoll__PostContent--editable,
	.generic_editable {
		&:hover {
			background-color: rgba($brand-warning, .35);
		}

		&:focus {
			background-color: transparent;
		}
	}

	.canvas {
		padding-top: 150px;
	}
}

.has-top-alert {
	
	.alert-top {

	}
}

.carousel-control {
	z-index: 2;
}

.carousel-play-button {
	position: absolute;
    top: 50%;
    left: 50%;
    font-size: 120px;
    height: 160px;
    line-height: 120px;
    width: 160px;
    margin-top: -120px;
    margin-left: -80px;
	color: #DDD;
	text-shadow: 2px 2px 0 rgba(#000, .2);

	&:hover, &:focus {
		color: #FFF;
		text-shadow: 5px 5px 2px rgba(#000, .3);
	}
}

img {
	max-width: 100%;
	height: auto;
}

.jumbotron .h1 p,
.jumbotron h1 p {
	font-size: $font-size-h1;
}

.delete-form {
	display: inline-block;
}

textarea.form-control {
	border-radius: 3px;
	max-width: 100%;
}

.page-newform {
	.radio input[type="radio"],
	.radio-inline input[type="radio"],
	.checkbox input[type="checkbox"],
	.checkbox-inline input[type="checkbox"] {
		margin-left: 0;
	}
}

.heading-stripe {
	background-color: #34A7D2;
	color: #FFF;
	padding-top: 50px;
	padding-bottom: 50px;
	text-align: center;

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		margin: 0;
	}
}

.VisionTable {
	text-align: center;
	border: 1px solid #ADD2DF;
	border-radius: 3px;
	background: #FBFEFF;
	padding: 15px;
	margin-bottom: 30px;
	color: #5F6063;

	table {
		width: 100%;
	}

	td {
		vertical-align: middle;

		@media (min-width: $screen-sm-min) {
			height: 200px;
			font-size: 18px;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}
}

.table {
	border-collapse: separate;
	
	.min {
		width: 1px;
		white-space: nowrap;
		overflow: hidden;
	}

	.padless {
		padding: 0;

		.btn {
			border-radius: 0;
			border-top-width: 0;
			border-bottom-width: 0;
		}
	}
}

.dl-horizontal.expandable dt {
	overflow: visible;
	white-space: normal;

	@media (min-width: $screen-lg-min) {
		width: 400px;
	}
}

.dl-horizontal.expandable dd {
	margin-bottom: 8px;
	@media (min-width: $screen-lg-min) {
		margin-left: 420px;
	}
}

.navbar-inverse.navbar-collapse {
	background: $navbar-inverse-bg;
	@media (min-width: $grid-float-breakpoint) {
		background: transparent;
	}
}

.EventsControls {

	.dropdown {
		margin-bottom: 1em;

	}

	.btn {
		width: 100%;
	}

	@media (min-width: $screen-sm-min) {
		display: flex;
		justify-content: space-between;

		.dropdown {
			margin-bottom: 0;
		}

		.btn {
			width: auto;
		}
	}
}