$font-family-sans-serif:  "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;

$brand-primary: #34A7D2;
$brand-success: #97BE59;
$brand-warning: #F4D03F;

$grid-float-breakpoint: 1200px;

$link-color: $brand-primary;

$btn-border-radius-base: 50vh;
$btn-border-radius-large: 50vh;
$btn-border-radius-small: 50vh;

$input-border-radius: 50vh;
$input-border-radius-large: 50vh;
$input-border-radius-small: 50vh;

$padding-base-vertical:     6px;
$padding-base-horizontal:   18px;
$padding-large-vertical:    10px;
$padding-large-horizontal:  30px;
$padding-small-vertical:    5px;
$padding-small-horizontal:  15px;
$padding-xs-vertical:       1px;
$padding-xs-horizontal:     5px;

$navbar-height: 100px;
$navbar-default-border: transparent;
$navbar-padding-vertical: 30px;
$navbar-default-toggle-border-color: transparent;
$navbar-default-toggle-icon-bar-bg: #FFF;
$navbar-default-toggle-hover-bg: #333;

$jumbotron-heading-font-size: 36px;