.EventList {
	list-style: none;
	padding: 0;

	&__Item {
		
		& > a {
			color: #555;
			position: relative;
			display: block;
			background-color: $gray-lighter;
			border-radius: 50px;
			min-height: 100px;
			margin-top: 50px;
			margin-bottom: 50px;
			text-align: center;
			padding: 40px 20px 20px;
			font-size: $font-size-h4;
			text-decoration: none;

			&:hover, &:focus {
				background-color: darken($gray-lighter, 2%);
				color: #000;

				.EventList__Price {
					background: #000;
				}
			}
		}
	}

	&__Link {

	}

	&__Type {
		position: absolute;
		top: -18px;
		left: 20px;
		border: 4px solid $body-bg;
		border-radius: 50vh;
		background-color: $gray;
		padding: 4px 12px;
		font-size: 16px;
		color: #FFF;
		text-transform: uppercase;

		&--event {
			background-color: $brand-success;
		}

		&--class {
			background-color: $brand-warning;
		}
	}

	&__Price {
		position: absolute;
		top: -25px;
		right: 0;
		border: 4px solid $body-bg;
		border-radius: 50vh;
		background-color: $gray;
		padding: 10px;
		font-size: 22px;
		color: #FFF;
		text-transform: uppercase;
	}

	&__Title {
		font-size: $font-size-h2;
	}

	&__Date {
		margin-bottom: 1em;
	}

	&__Preview {

	}

	&__ReadMore {
		@extend .btn;
		@extend .btn-primary;
	}
}

.EventInfo {
	position: relative;
	background-color: $brand-primary;
	border-radius: 50px;
	min-height: 100px;
	margin-top: 50px;
	margin-bottom: 50px;
	color: #FFF;
	text-align: center;
	padding: 40px 20px 20px;
	font-size: $font-size-h4;

	&__Price {
		position: absolute;
		top: -25px;
		right: 0;
		border: 4px solid $body-bg;
		border-radius: 50vh;
		background-color: $gray;
		padding: 10px;
		font-size: 22px;
		text-transform: uppercase;
	}

	&__Type {
		position: absolute;
		top: -18px;
		left: 20px;
		border: 4px solid $body-bg;
		border-radius: 50vh;
		background-color: $gray;
		padding: 4px 12px;
		font-size: 16px;
		color: #FFF;
		text-transform: uppercase;

		&--event {
			background-color: $brand-success;
		}

		&--class {
			background-color: $brand-warning;
		}
	}
}

.EventDescription {
	margin-bottom: 40px;
}

.StartEventRegistration {
	text-align: right;

	&__QuantityInput {
		@extend .form-control;
		display: inline-block;
		width: 80px;
		border-radius: 2px;
		margin-right: 50px;
		margin-left: 10px;
		text-align: center;
	}
}

.payment-form {
	.payment-errors {
		@extend .alert;
		@extend .alert-danger;
		display: block;

		&:empty {
			display: none;
		}
	}

	.form-control {
		@extend .input-lg;
	}

	.col-exp-month,
	.col-exp-year,
	.col-cvc {
		@include make-xs-column(12);

		.form-control {
			text-align: center;
		}
	}

	.col-exp-month {
		width: 125px;

		label {
			white-space: nowrap;
		}

		.form-group {
			position: relative;

			&:before {
				content: '/';
				position: absolute;
				right: -18px;
				top: 26px;
				font-size: 20px;
			}
		}
	}

	.col-exp-year {
		width: 170px;
	}

	.col-cvc {
		width: 165px;
	}

	.row {
		
	}
}