.BusinessListContainer {
	background-color: #F0F0F0;
}
.BusinessList {
	@include make-row(5px);

	&__Item {
		@include make-xs-column(12, 5px);
		@include make-sm-column(6, 5px);
		@include make-md-column(4, 5px);
		text-align: center;
		margin-bottom: 5px;
	}

	&__InnerItem {
		background-color: #FFF;
		padding: 10px;

		@media (min-width: $screen-sm-min) {
			height: 340px;
		}

		// @media (min-width: $screen-md-min) {
		// 	height: 340px;
		// }
	}

	&__ImageWrapper {
		img {
			max-width: 100%;
			height: auto;
			max-height: 140px;
		}

		margin-bottom: 20px;
	}

	&__Title {
		margin-bottom: 20px;
		font-size: 18px;

		@media (min-width: $screen-sm-min) {
			margin-bottom: 40px;
		}
	}

	&__Category {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}

	&__Button {
		margin-bottom: 20px;
	}
}