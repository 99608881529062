.BusinessBanner {
	@extend .Banner;
}

.BusinessTabs {
	@extend .AboutTabs;

	.nav-tabs > li {
		width: 50%;
	}
}

.KinectFrame {
	position: relative;

	&__Icon {
		background-color: $brand-primary;
		text-align: center;
		width: 120px;
		height: 120px;
		border-radius: 50vh;
		float: right;
		margin-bottom: -130px;
		position: relative;
		z-index: 2;

		[class^="launch-icon-"]:before, [class*=" launch-icon-"]:before {
			font-size: 72px;
			line-height: 120px;
			color: #313B2F;
		}
	}

	&__Banner {
		@extend .covered;
		height: 340px;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 200%;
			background-color: #FFF;
			height: 100%;
			top: -80%;
			left: -50%;
			right: -50%;
			transform: rotate(4deg);
			pointer-events: none;
		}
	}

	&__Content {
		font-size: 24px;
		color: #5F6063;
		text-align: center;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	&--left {
		.KinectFrame__Icon {
			float: left;
		}

		.KinectFrame__Banner:before {
			transform: rotate(-4deg);
		}
	}
}