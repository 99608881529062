.CallCards {
	@include make-row();

	&__Card {
		@include make-xs-column(12);
		@include make-lg-column(4);
		margin-bottom: 1em;

		> .covered {
			padding: 2em 2em;
		}
	}

	&__Button {
		@extend .btn;
		@extend .btn-primary;
		margin-top: 4em;
	}

	&__Content {
		margin-top: 3em;
		font-size: $font-size-h4;
	}
}