.page-launchgames {

	.games-banner {
		min-height: 300px;

		@media (min-width: $screen-sm-min) {
			min-height: 400px;
		}

		// @media (min-width: $screen-md-min) {
		// 	min-height: 400px;
		// }

		.container {
			position: relative;

			.logo {
				width: 324px;
				height: auto;
				max-width: 50%;
				position: absolute;
				top: 30px;
				left: 15px;

				img {
					display: block;
				}
			}
		}
	}

	.callout {
		.callout-image {
			box-shadow: 0 2px 4px rgba(0,0,0,0.26);
		}

		.callout-title {
			font-size: 48px;
			text-transform: uppercase;
		}

		.callout-text {

			@media (min-width: $screen-sm-min) {
				padding-left: 25px;
			}
		}

		.callout-description {
			font-size: 24px;
		}

		.callout-date {
			font-size: 24px;
			color: #33A6D2;
			text-transform: uppercase;
		}
	}

	.competition-text {
		text-align: center;

		.badge {
			background-color: #33A6D2;
			color: #FFF;
		}

		@media (min-width: $screen-sm-min) {
			&, .badge {
				font-size: 38px;
			}
		}
	}

	.round-icon {
		width: 226px;
		max-width: 100%;
		height: auto;
	}

	.round-icon-label {
		text-transform: uppercase;

		@media (min-width: $screen-md-min) {
			font-size: 20px;
		}
	}

	.sponsorship-band {
		text-align: center;
		padding: 30px 0;
		background: #212121;

		img {
			max-height: 200px;
			width: auto;
		}
	}

	.awards {
		.row {
			position: relative;

			.interjector {
				position: absolute;
				top: 0;
				left: 50%;
				width: 40px;
				text-align: center;
				margin-left: -20px;

				@media (min-width: $screen-md-min) {
					top: 135px;
					font-size: 24px;
				}
			}
		}
	}

	.event-card {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
		margin-bottom: 15px;
		overflow: hidden;
	}

	.event-image {
		float: left;
		width: 120px;
		height: 120px;
		margin-right: 25px;

		img {
			max-width: 100%;
			height: auto;
		}

		@media (min-width: $screen-sm-min) {
			float: none;
			width: 100%;
			height: auto;
		}
	}

	.event-content {
		padding: 15px;

		@media (min-width: $screen-sm-min) {
			text-align: center;
			padding: 40px;
		}
	}
}
