.Footer {
	&__Subscribe {
		@extend .jumbotron;
		@extend .text-center;
		@include gradient-directional(darken(rgba($brand-primary, .8), 0%), darken(rgba(#11345C, .8), 0%));
		color: #FFF;
		text-transform: lowercase;
		margin: 0;

		@media (min-width: $screen-sm-min) {
			padding: 4em 0;
		}

		@media (min-width: $screen-md-min) {
			padding: 6em 0;
		}

		@media (min-width: $screen-lg-min) {
			padding: 10em 0;
		}

		&Form {
			@include make-xs-column(12);
			@include make-sm-column(8);
			@include make-sm-column-offset(2);
			@include make-md-column(6);
			@include make-md-column-offset(3);

			.form-control {
				border: 0;
				text-align: center;
				box-shadow: none;

				&::placeholder {
					text-transform: uppercase;
				}
			}

			.input-group-btn .btn {
				box-shadow: none;
				font-size: 18px;
				padding-top: 3px;
			}

			.form-control,
			.input-group-btn .btn {
				height: 36px;
			}
		}
	}

	&__Contact {
		@include container-fixed;
		@extend .text-center;
		background: #434343;
		padding-top: 4em;
		padding-bottom: 4em;
		text-transform: uppercase;
		color: #FFF;

		[class^="launch-icon-"]:before, [class*=" launch-icon-"]:before {
			font-size: $font-size-h2;
			margin-bottom: 0.25em;
		}

		&Logo {
			margin-bottom: 2em;
		}

		&Information {
			@include make-row;

			label {
				display: block;
			}

			a {
				color: #FFF;
				display: block;
			}
		}

		&Column {
			@include make-xs-column(12);
			@include make-sm-column(4);
		}
	}

	&__Menu {
		@media (min-width: $screen-sm-min) {
			padding: 4em 0;
		}

		@media (min-width: $screen-md-min) {
			padding: 5em 0;
		}

		@media (min-width: $screen-lg-min) {
			padding: 6em 0;
		}

		&Inner {
			position: relative;
			margin-top: 2em;
			padding-bottom: 2em;
			text-align: center;

			@media (min-width: $screen-md-min) {
				padding-left: 50px;
				padding-right: 130px;
			}
		}

		&Logo {
			margin-bottom: 1em;
			display: block;
		}

		&Nav,
		&Social {
			font-size: 0;
			@extend .list-unstyled;

			li {
				vertical-align: middle;
				font-size: $font-size-base;
			}
		}

		&Social {
			li {
				display: inline-block;
			}

			a {
				color: $gray-light;
				text-decoration: none;
				font-size: 18px;

				&:hover, &:focus {
					color: $gray;
				}
			}
		}

		&Nav {
			text-align: center;

			a {
				margin: 0 $padding-small-horizontal;
				text-transform: uppercase;
				color: #5F6063;
				text-decoration: none;

				&:hover, &:active {
					color: #111;
				}
			}
		}

		&Copyright {
			text-align: center;
			color: lighten($gray-light, 10%);
			text-transform: uppercase;
		}

		@media (min-width: $screen-md-min) {
			&Social {
				position: absolute;
				right: 0;
				top: 0;
				margin: -6px 0 0;
			}

			&Logo {
				position: absolute;
				left: 0;
				top: 0;
			}

			&Nav {
				li {
					display: inline-block;
				}
			}
		}
	}
}